export default class CertificateDataCollection {
    constructor(collection = []) {
        this.collection = collection;
    }

    insertIfNotExist(certificateData) {
        let found = this.searchInCollectionByCounterSlug(certificateData.counter, certificateData.slug);
        if (found) {
            this.deleteInCollectionByCounterSlug(certificateData.counter, certificateData.slug);
        }
        this.collection.push(certificateData);
    }

    deleteInCollectionByCounterSlug(counter, slug) {
        this.collection = this.collection.filter((currentValue) => {
            if (currentValue.counter === counter && currentValue.slug === slug) {
                return;
            }
            return currentValue;
        });
    }

    deleteCollectionByCounter(counter) {
        this.collection = this.collection.filter((item) => {
            if(item.counter !== counter){
                return item;
            }
        })
    }

    filterByCounterSlug(counter, slug) {
        return this.collection.reduce((accumulator, currentValue) => {
            if (currentValue.counter === counter && currentValue.slug === slug) {
                return accumulator = currentValue;
            }
            return accumulator;
        }, {});
    }

    searchInCollectionByCounterSlug(counter, slug) {
        return this.collection.reduce((accumulator, currentValue) => {
            if (currentValue.counter === counter && currentValue.slug === slug) {
                return accumulator = true;
            }
            return accumulator
        }, false);
    }

    get() {
        return this.collection;
    }
}