import Vue from 'vue';

const tracking = {
    state: () => ({
        trackingData: {
            track: '',
            source: '',
            url: '',
            event: '',
            target: '',
            cookieId: '',
            device: '',
            metadata: {},
        },
    }),
    mutations: {
        updateTrackingAtribute(state, {key, value}) {
            Vue.set(state.trackingData, key, value);
        },
    },
    actions: {
        updateTrackingAtribute({ commit }, payload) {
            commit('updateTrackingAtribute', payload);
        },
    },
    getters: {
        trackingData: state => state.trackingData,
    },
}
export default tracking