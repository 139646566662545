import LogController from "../../controllers/servicos/LogController";

export default class Service {
    constructor(services) {
        this._services = services;
        this._cartType = '';
    }

    getServiceBySlug(slug) {
        let founded = this._services.filter((service) => {
            return service[2] === slug
        });
        if (founded.length > 0) {
            return this._setCartITem({
                name: founded[0][0],
                priceReference: founded[0][1],
                slug: founded[0][2],
            });
        } else {
            //return false;
            LogController.save({
                message: 'Service not found: in getServiceBySlug',
                tags: 'service, certidao',
                data: {
                    slug,
                    founded,
                },
            }).then((r) => {
                console.log(r);
                throw new Error('Service NOT found: ' + slug);
            });
        }
    }

    getServiceByInitialSlug(slug) {
        let founded = this._services.filter((service) => {
            return service[2].includes(slug);
        });
        if (founded.length > 0) {
            return this._setCartITem({
                name: founded[0][0],
                priceReference: 0,
                slug: founded[0][2],
            });
        } else {
            LogController.save({
                message: 'Service not found: in SlugInitial',
                tags: 'service, certidao',
                data: {
                    slug,
                    founded,
                },
            }).then((r) => {
                console.log(r)
                throw new Error('Service NOT found: ' + slug);
            });
        }
    }

    setCartType(cartType) {
        this._cartType = cartType;
    }

    _setCartITem(info) {
        this._cartType.basicInfo(info);
        return this._cartType;
    }
}