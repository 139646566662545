import ClientsController from "../../../controllers/servicos/ClientsController";

const clients = {
    state: () => ({
        clientCounter: 0,
        clientList: {},
    }),
    mutations: {
        clientCounterChange(state, counter) {
            state.clientCounter = counter;
        },
        clientListChange(state, list) {
            state.clientList = list;
        }
    },
    actions: {
        findClients({commit}) {
            let clientsController = new ClientsController();
            clientsController.findClients()
                .then(clients => {
                    commit('clientCounterChange', clients.count);
                    commit('clientListChange', clients.list);
                });
        }
    },
    getters: {
        clientCounter: state => {
            return state.clientCounter;
        },
        clientList: state => {
            return state.clientList;
        }
    }
}
export default clients