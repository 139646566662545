import LeadController from "../../../controllers/servicos/LeadController";

const customer = {
    state: () => ({
        customer: {},
        customerIsNew: false,
        membersAreaCredentialsMailSent: false,
        customerAddressList: []
    }),
    mutations: {
        setCustomer(state, customer) {
            state.customer = customer;
        },
        setCustomerId(state, id) {
            state.customer.id = id;
        },
        setCustomerDocument(state, document) {
            state.customer.document = document;
        },
        setCustomerIsNew(state, payload) {
            state.customerIsNew = payload;
        },
        setMembersAreaCredentialsMailSent(state, payload) {
            state.membersAreaCredentialsMailSent = payload;
        },
        setCustomerType(state, type) {
            state.customer = {
                ...state.customer,
                type
            }
        }
    },
    actions: {
        setCustomer({commit}, customer) {
            commit('setCustomer', customer);
            if(!!customer?.email && !!customer?.phone) {
                let leadController = new LeadController();
                leadController.saveLead(customer);
            }
        },
        setCustomerId({commit}, id) {
            commit('setCustomerId', id);
        },
        setCustomerType({commit}, type) {
            commit('setCustomerType', type);
        },
        setCustomerDocument({commit}, document) {
            commit('setCustomerDocument', document);
        },
        setCustomerIsNew({commit}, payload) {
            commit('setCustomerIsNew', payload);
        },
        setMembersAreaCredentialsMailSent({commit}, payload) {
            commit('setMembersAreaCredentialsMailSent', payload);
        }
    },
    getters: {
        customer: state => state.customer,
        customerId: state => state.customer?.id ?? '',
        customerType: state => state.customer?.type ?? '',
        customerDocument: state => state.customer?.document ?? '',
        customerIsNew: state => state.customerIsNew,
        customerAddressList: state => state.customerAddressList,
        membersAreaCredentialsMailSent: state => state.membersAreaCredentialsMailSent,
    }
}
export default customer