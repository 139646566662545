export default class Cart {
    constructor(cart) {
        this.cart = cart;
        if (!this.cart) {
            this.cart = [];
        }
    }

    push(counter, title, item) {
        let cartItem = '';
        if (this._hasThatCounter(counter)) {
            cartItem = Object.assign({}, this.cart[counter], {[title]: item.get()});
        } else {
            cartItem = {[title]: item.get()};
        }
        return Object.assign({}, this.cart, {[counter]: cartItem});
    }

    _hasThatCounter(counter) {
        return counter in this.cart;
    }

    _hasThatItem(counter, item) {
        return item in this.cart[counter];
    }

    remove(counter) {
        if (this._hasThatCounter(counter)) {
            delete this.cart[counter];
        }
        return this.cart;
    }

    removeItem(counter, name) {
        if (this._hasThatCounter(counter)) {
            if (this._hasThatItem(counter, name)) {
                delete this.cart[counter][name];
            }
        }
        return this.cart;
    }

    removeCertificate(counter) {
        this.removeItem(counter, 'certificate');
        return this.cart;
    }
}