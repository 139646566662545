const loading = {
    // state: () => ({ ... }),
    // mutations: {
    //     increment (state, payload) {
    //         state.count += payload.amount
    //     }
    //     // store.commit('increment', {
    //     //     amount: 10
    //     // })
    // },
    // actions: {
    //     incrementAsync ({ commit }, payload) {
    //         setTimeout(() => {
    //             commit('increment')
    //         }, 1000)
    //     }
    //     // store.dispatch('incrementAsync', {
    //     //     amount: 10
    //     // })
    // },
    // getters: {
    //     doneTodos: state => {
    //         return state.todos.filter(todo => todo.done)
    //     },
    //     // computed: {
    //     //     doneTodosCount () {
    //     //         return this.$store.getters.doneTodosCount
    //     //     }
    //     // }
    // }
}
export default loading