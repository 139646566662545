import Vuex from 'vuex';
import mutations from './mutations';
import * as actions from './actions';

import certificate from './modules/certificate';
import sale from './modules/sale';
import cart from './modules/cart';
import certificateNumber from './modules/certificateNumber';
import loading from './modules/loading';
import clients from "./modules/clients";
import service from "./modules/service";
import payButton from "./modules/payButton";
import customer from "./modules/customer";
import lead from "./modules/lead";
import paymentLink from "./modules/paymentLink";
import tracking from "./modules/tracking";

Vue.use(Vuex);

const store = new Vuex.Store({
    mutations,
    actions,
    modules: {
        certificate,
        sale,
        cart,
        certificateNumber,
        loading,
        clients,
        service,
        payButton,
        customer,
        lead,
        paymentLink,
        tracking,
    }
})

export default store;