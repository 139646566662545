import {ResourceAdapter} from "../../../../../painel/app/js/Service/Adapters/ResourceAdapter";
import store from '../../vue/store/index';

const baseUrl = BACKEND + '/api/leads';

export default class LeadController {
    store(name = null, email, phone = null, type = null, source = null, affiliate = null) {
        const resource = new ResourceAdapter(baseUrl);
        return resource.post({ name, email, phone, type, source, affiliate })
            .then((result) => { return result })
            .catch(function (error) { console.log(error) });
    }

    saveLead(customer) {
        const source = store.getters['source'];
        const affiliateLink = store.getters['affiliateLink'];
        this.store(customer.name, customer.email, customer.phone, customer.type, source, affiliateLink)
            .then((response) => {
                store.dispatch('setLeadId', response.lead.id);
                store.dispatch('setLead', response.lead);
            })
            .catch((response) => {
                console.log(response)
            })
    }
}