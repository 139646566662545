import CertificateDataCollection from "../../../models/formData/CertificateDataColletion";

const certificate = {
    state: () => ({
        certificateData: [],
    }),
    mutations: {
        certificateInsertField(state, payload) {
            let certificateDataCollection = new CertificateDataCollection(state.certificateData);
            certificateDataCollection.insertIfNotExist(payload);
            state.certificateData = certificateDataCollection.get();
        },
        certificateDeleteField(state, payload) {
            let certificateDataCollection = new CertificateDataCollection(state.certificateData);
            certificateDataCollection.deleteInCollectionByCounterSlug(payload.counter, payload.slug);
            state.certificateData = certificateDataCollection.get();
        },
        certificateDataExclude(state, payload){
            let certificateDataCollection = new CertificateDataCollection(state.certificateData);
            certificateDataCollection.deleteCollectionByCounter(payload);
            state.certificateData = certificateDataCollection.get();
        }
    },
    getters: {
        certificateData: (state) => {
            return state.certificateData;
        },
        certificateByCounterSlug: (state) => ({counter, slug}) => {
            let certificateDataCollection = new CertificateDataCollection(state.certificateData);
            return certificateDataCollection.searchInCollectionByCounterSlug({
                counter: counter, slug: slug
            });
        },
        certificateByCounterSlugValue: (state) => ({counter, slug}) => {
            let certificateDataCollection = new CertificateDataCollection(state.certificateData);
            let certificate = certificateDataCollection.filterByCounterSlug(counter, slug);
            return certificate.value;
        },
        deliveryResult: (state) => {
            let deliveryResultLocal = false;
            state.certificateData.forEach((item) => {
                if (item.slug === 'formato') {
                    if (item.value === 'papel' || item.value === 'papeleletronica') {
                        deliveryResultLocal = true;
                    }
                }
            });
            return deliveryResultLocal;
        },
    }
}
export default certificate