import {CartItemStandard} from "../../../models/cart/cartItem/CartItemStandard";

const certificateNumber = {
    state: () => ({
        certificateNumberList: [1],
        certificateNumberCounter: 1,
        certificateNumberActive: 1,
    }),
    mutations: {
        certificateNumberActiveChange(state, payload) {
            payload = parseInt(payload);
            if(state.certificateNumberList.includes(payload)){
                state.certificateNumberActive = payload;
            }
        },
        certificateNumberListRemove(state, payload) {
            let actualState = state.certificateNumberList;
            state.certificateNumberList = actualState.filter((item) => {
                if(item !== payload){
                    return item;
                }
            });
            state.certificateNumberActive = 1;
        },
        certificateNumberListInsert(state, messages) {
            state.certificateNumberCounter++;
            state.certificateNumberList.push(state.certificateNumberCounter);
            state.certificateNumberActive = state.certificateNumberCounter;
        },
    },
    getters: {
        certificateNumberList: (state) => {
            return state.certificateNumberList;
        },
        certificateNumberCounter: (state) => {
            return state.certificateNumberCounter;
        },
        certificateNumberActive: (state) => {
            return state.certificateNumberActive;
        },
    }
}
export default certificateNumber