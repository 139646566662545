export default class CartItem {
    constructor() {
        this._slug = '';
        this._name = ''
        this._priceReference = '';
        this._price = 0;
    }

    basicInfo({slug, name, priceReference}) {
        this._slug = slug;
        this._name = name;
        this._priceReference = priceReference;
    }

    _setPrice() {
        throw new Error('Need Implement the Method: _setPrice');
    }

    get() {
        this._setPrice();
        return {
            slug: this._slug,
            name: this._name,
            priceReference: this._priceReference,
            price: this._price
        }
    }
}