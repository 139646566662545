//Menu responsivo
let btnAbrir = document.querySelector(".btn_menu");
let btnFechar = document.querySelector(".menu_close");
let body = document.querySelector("body");
let menu = document.querySelector(".header_menu");

btnAbrir.addEventListener("click", function () {
    menu.style.display = "block";
    body.style.overflow = "hidden";
});

btnFechar.addEventListener("click", function () {
    menu.style.display = "none";
    body.style.overflow = "auto";
});

//Modal 1
let btnAbrirModal1 = document.querySelector(".link_modal1");
let btnFecharModal1 = document.querySelector(".close_modal1");
let modal1 = document.querySelector(".modal1");

if(btnAbrirModal1){
    btnAbrirModal1.addEventListener("click", function () {
        modal1.style.display = "block";
        body.style.overflow = "hidden";
    });

    btnFecharModal1.addEventListener("click", function () {
        modal1.style.display = "none";
        body.style.overflow = "auto";
    });
}

//Modal 2
let btnAbrirModal2 = document.querySelector(".link_modal2");
let btnFecharModal2 = document.querySelector(".close_modal2");
let modal2 = document.querySelector(".modal2");

if(btnAbrirModal2){
    btnAbrirModal2.addEventListener("click", function () {
        modal2.style.display = "block";
        body.style.overflow = "hidden";
    });

    btnFecharModal2.addEventListener("click", function () {
        modal2.style.display = "none";
        body.style.overflow = "auto";
    });
}