import Service from "../../../models/services/Service";

const service = {
    state: () => ({
        services: [],
    }),
    mutations: {
        setServices(state, services) {
            state.services = services;
        },
    },
    actions: {
        setServices({commit}, services) {
            commit('setServices', services);
        },
    },
    getters: {
        getServiceBySlug: (state) => ({certificateSlug, cartType}) => {
            const serviceModel = new Service(state.services);
            serviceModel.setCartType(cartType);
            if(certificateSlug.validData()){
                return serviceModel.getServiceBySlug(certificateSlug.get());
            } else {
                return serviceModel.getServiceByInitialSlug(certificateSlug.getBaseSlug());
            }
        }
    }
}
export default service