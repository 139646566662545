const lead = {
    state: () => ({
        leadId: null,
        lead: {}
    }),
    mutations: {
        setLead(state, lead) {
            state.lead = lead;
        },
        setLeadId(state, id) {
            state.leadId = id;
        }
    },
    actions: {
        setLead({commit}, lead) {
            commit('setLead', lead);
        },
        setLeadId({commit}, id) {
            commit('setLeadId', id);
        },
    },
    getters: {
        lead: (state) => {
            return state.customer;
        },
        leadId: (state) => {
            return state.leadId;
        }
    }
}
export default lead