import {validacaoTelefone, retornaApenasNumero, validacaoCard, validacaoAno, validacaoMes, validacaoSeguranca} from '../utils/valida.js';

$("#telefone").change(function () {
    let input = $(this);
    if (validacaoTelefone(input.val())) {
        input.parent().removeClass('input_text_error');
    } else {
        input.parent().addClass('input_text_error');
    }
});

$(".card-mask").change(function () {
    let input = retornaApenasNumero($(this).val());
    if (validacaoCard(input)) {
        $(this).parent().removeClass('input_text_error');
    } else {
        $(this).parent().addClass('input_text_error');
    }
});

$(".year-mask").change(function () {
    let input = $(this).val();
    if (validacaoAno(input)) {
        $(this).parent().removeClass('input_text_error');
    } else {
        $(this).parent().addClass('input_text_error');
    }
});

$(".month-mask").change(function () {
    let input = $(this).val();
    if (validacaoMes(input)) {
        $(this).parent().removeClass('input_text_error');
    } else {
        $(this).parent().addClass('input_text_error');
    }
});

$(".secure-mask").change(function () {
    let input = $(this).val();
    if (validacaoSeguranca(input)) {
        $(this).parent().removeClass('input_text_error');
    } else {
        $(this).parent().addClass('input_text_error');
    }
});

$(document).on("change", ".input_text_error", function () {
    $(this).removeClass('input_text_error');
});

$(document).on("change", ".input_select_error", function () {
    $(this).removeClass('input_select_error');
});