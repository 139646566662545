import Cart from "../../../models/cart/Cart";

const cart = {
    state: () => ({
        cartItems: false,
        removedItem: {},
        commissionAdv: 0,
        commissionAdvMinusTax: 0,
        discountPercentage: 0,
        commissionAdvFocusOut: false,
    }),
    mutations: {
        cartPush(state, {counter, title, item}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = cart.push(counter, title, item);
        },
        cartRemove(state, {counter}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.remove(counter);
        },
        cartRemoveItem(state, {counter, name}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.removeItem(counter, name);
            state.removedItem = {counter, name}
        },
        cartRemoveCertificate(state, {counter}) {
            let cart = new Cart(state.cartItems);
            state.cartItems = false;
            state.cartItems = cart.removeCertificate(counter);
        },
        setDiscountPercentage(state, payload) {
            state.discountPercentage = payload;
        },
        setCommissionAdv(state, payload) {
            state.commissionAdv = payload;
        },
        setCommissionAdvMinusTax(state, payload) {
            state.commissionAdvMinusTax = payload;
        },
        toggleCommissionAdvFocusOut(state) {
            state.commissionAdvFocusOut = !state.commissionAdvFocusOut;
        },
    },
    actions: {
        setDiscountPercentage({ commit }, payload) {
            commit('setDiscountPercentage', payload);
        },
        setCommissionAdv({ commit, getters }, payload) {
            commit('setCommissionAdv', payload)
            commit('setCommissionAdvMinusTax', payload - getters.taxCommissionAdv);
        },
        toggleCommissionAdvFocusOut({ commit }) {
            commit('toggleCommissionAdvFocusOut');
        },
    },
    getters: {
        cartItems: state => state.cartItems,
        cartDeliveryItems({cartItems}){
            let itemsArray = Object.values(cartItems);
            let itemsKeys = Object.keys(cartItems);
            let filtered = {};
            for (let i=0; i < itemsKeys.length; i++) {
                if (itemsKeys[i] === '0'){
                    filtered = itemsArray[i];
                }
            }
            return filtered;
        },
        cartItemsWithoutDelivery({cartItems}){
            let itemsArray = Object.values(cartItems);
            let itemsKeys = Object.keys(cartItems);
            let filtered = {};
            for (let i=0; i < itemsKeys.length; i++) {
                if (itemsKeys[i] !== '0'){
                    filtered = Object.assign({}, filtered, {[itemsKeys[i]]:itemsArray[i]})
                }
            }
            return filtered;
        },
        cartAmount(state) {
            let amount = 0;
            if (typeof state.cartItems === 'object') {
                let cartArray = Object.values(state.cartItems);
                cartArray.forEach((items) => {
                    let itemsArray = Object.values(items);
                    itemsArray.forEach((item) => {
                        amount += parseFloat(item.price);
                    });
                })
            }
            return amount;
        },
        discountPercentage: state => state.discountPercentage,
        getDiscountAmount(state, getters) {
            return parseFloat(getters.cartAmount * (state.discountPercentage / 100)).toFixed(2)
        },
        removedItem: state => state.removedItem,
        commissionAdv: state => state.commissionAdv,
        commissionAdvMinusTax: state => state.commissionAdvMinusTax,
        taxCommissionAdv(state) {
            if (!(state.commissionAdv > 0)) return 0;
            return state.commissionAdv * (16 / 100);
        },
        cartAmountMinusDiscount(state, getters) {
            return getters.cartAmount - getters.getDiscountAmount
        },
        commissionAdvFocusOut: state => state.commissionAdvFocusOut,
    },
}
export default cart