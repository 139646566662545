import { ResourceAdapter } from "../../../../../painel/app/js/Service/Adapters/ResourceAdapter";
import { DOMAIN } from "../../utils/domains";

export default class LogController {
    static save({message, tags, data}) {
        let resource = new ResourceAdapter(DOMAIN + '/log');
        data = JSON.stringify(data);
        return resource.post({message, tags, data})
            .then(function (service) {
                return service;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}