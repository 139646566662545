const payButton = {
    state: () => ({
        payButtonName: 'Pagar Certidão',
        emitButtonName: 'Verificar Disponibilidade',
        processButtonName: 'Processando...',
        waitingButtonName: 'Aguardando pagamento...',
        showCheckAvailableModal: false,
        loadingCheckAvailableModal: false,
        paymentMethod: '',
    }),
    mutations: {
        changePayButtonName(state, buttonName) {
            state.payButtonName = buttonName;
        },
        changeEmitButtonName(state, buttonName) {
            state.emitButtonName = buttonName;
        },
        changeProcessButtonName(state, buttonName) {
            state.processButtonName = buttonName;
        },
        changeWaitingButtonName(state, payload) {
            state.waitingButtonName = payload;
        },
        changeShowCheckAvailableModal(state, payload) {
            state.showCheckAvailableModal = payload;
        },
        changeLoadingCheckAvailableModal(state, payload) {
            state.loadingCheckAvailableModal = payload;
        },
        changePaymentMethod(state, paymentMethod) {
            state.paymentMethod = paymentMethod;
        },
    },
    actions: {
        changePayButtonName({ commit }, buttonName) {
            commit('changePayButtonName', buttonName);
        },
        changeEmitButtonName({ commit }, buttonName) {
            commit('changeEmitButtonName', buttonName);
        },
        changeProcessButtonName({ commit }, buttonName) {
            commit('changeProcessButtonName', buttonName);
        },
        changeWaitingButtonName({ commit }, payload) {
            commit('changeWaitingButtonName', payload);
        },
        changeShowCheckAvailableModal({ commit }, payload) {
            commit('changeShowCheckAvailableModal', payload);
        },
        changeLoadingCheckAvailableModal({ commit }, payload) {
            commit('changeLoadingCheckAvailableModal', payload);
        },
        resetButtonName({ commit }) {
            commit('changePayButtonName', 'Pagar Certidão');
            commit('changeEmitButtonName', 'Verificar Disponibilidade');
            commit('changeProcessButtonName', 'Processando...');
        },
        changePaymentMethod({ commit }, paymentMethod) {
            commit('changePaymentMethod', paymentMethod);
        },
    },
    getters: {
        payButtonName: state => state.payButtonName,
        emitButtonName: state => state.emitButtonName,
        processButtonName: state => state.processButtonName,
        waitingButtonName: state => state.waitingButtonName,
        showCheckAvailableModal: state => state.showCheckAvailableModal,
        loadingCheckAvailableModal: state => state.loadingCheckAvailableModal,
        paymentMethod: state => state.paymentMethod,
    }
}
export default payButton