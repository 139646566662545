function iniciarContador(inicial) {
    let count = inicial;
    setInterval(function () {
        let campoHtml = document.getElementById("contador");
        campoHtml.innerHTML = count;
        if (getRandomInt(2) === 1) {
            if (count < 120) {
                count += getRandomInt(3);
            } else {
                count -= 1;
            }
        } else {
            if (count > 10) {
                count -= getRandomInt(3);
            } else {
                count += 1;
            }
        }

    }, getRandomInt(1500) + 1000)
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

iniciarContador(58);