import store from "../index";

const sale = {
    state: () => ({
        saleId: '',
        paramCertificate: '',
        paramGroup: '',
        saleLevel: 1,
        paymentLevel: 1,
        saleFormData: {},
        salePaying: false,
        saleButtonActive: false,
        saleInternational: false,
        termsAgree: false,
        affiliateLink: '',
        source: '',
        cashBack: false,
        cupom: '',
        address: {
            saveAddress: false,
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            uf: '',
            country: ''
        },
        deliveryAdditional: {
            sedex: false,
            avisoRecebimento: false,
            deliveryInternational: false,
        },
        deliveryAdditionalHasChanged: false,
        addressPayment: {
            saveAddress: false,
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            uf: '',
            country: ''
        },
        savingDeliveryAddress: false,
        certificateSelectedNotaries: {},
        certificateSelectedStates: {},
        certificateSelectedCities: {}
    }),
    mutations: {
        saleChangeId(state, saleId) {
            state.saleId = saleId;
        },
        saleChangeLevel(state, payload) {
            state.saleLevel = payload;
        },
        changePaymentLevel(state, payload) {
            state.paymentLevel = payload;
        },
        saleChangeFormData(state, payload) {
            state.saleFormData = payload;
        },
        salePayingChange(state, booleanPaying){
            state.salePaying = booleanPaying;
        },
        saleButtonActiveChange(state, booleanButton){
            state.saleButtonActive = booleanButton;
        },
        saleChangeInternational(state, saleInternational) {
            state.saleInternational = saleInternational;
        },
        saleTermsAgree(state, payload) {
            state.termsAgree = payload;
        },
        saleChangeAffiliateLink(state, payload) {
            state.affiliateLink = payload;
        },
        saleChangeSource(state, payload) {
            state.source = payload;
        },
        saleChangeCashBack(state, payload) {
            state.cashBack = payload;
        },
        saleChangeCupom(state, payload) {
            state.cupom = payload;
        },
        saleChangeSaveAddress(state, payload) {
            state.address.saveAddress = payload;
        },
        saleChangeAddressAttribute(state, payload) {
            Vue.set(state.address, payload.attribute, payload.value);
        },
        saleChangeAddress(state, payload) {
            state.address = payload;
        },
        saleClearAddress(state) {
            state.address  = {
                saveAddress: true,
                zipCode: '',
                street: '',
                number: '',
                complement: '',
                district: '',
                city: '',
                uf: '',
                country: '',
                additional: {
                    sedex: false,
                    avisoRecebimento: false,
                    deliveryInternational: false,
                }
            };
        },
        saleChangeAddressPayment(state, payload) {
            state.addressPayment = payload;
        },
        saleClearAddressPayment(state) {
            state.addressPayment  = {
                saveAddress: false,
                zipCode: '',
                street: '',
                number: '',
                complement: '',
                district: '',
                city: '',
                uf: '',
                country: ''
            };
        },
        setCertificateSelectedNotaries(state, payload) {
            state.certificateSelectedNotaries[payload.certificateCounter] = payload.notaryName
        },
        setCertificateSelectedStates(state, payload) {
            state.certificateSelectedStates[payload.certificateCounter] = payload.stateName
        },
        setCertificateSelectedCities(state, payload) {
            state.certificateSelectedCities[payload.certificateCounter] = payload.cityName
        },
        deleteCertificateSelectedNotaries(state, certificateCounter) {
            delete state.certificateSelectedNotaries[certificateCounter]
        },
        deleteCertificateSelectedStates(state, certificateCounter) {
            delete state.certificateSelectedStates[certificateCounter]
        },
        deleteCertificateSelectedCities(state, certificateCounter) {
            delete state.certificateSelectedCities[certificateCounter]
        },
        changSaleId(state, payload) {
            state.saleId = payload
        },
        changeSavingDeliveryAddress(state, payload) {
            state.savingDeliveryAddress = payload
        },
        changeDeliveryAdditionalHasChanged(state, payload) {
            state.deliveryAdditionalHasChanged = payload
        },
        setParamCertificate(state, payload) {
            state.paramCertificate = payload
        },
        setParamGroup(state, payload) {
            state.paramGroup = payload
        },
    },
    actions: {
        saleChangeLevel({ commit, dispatch }, payload) {
            commit('saleChangeLevel', payload);
            dispatch('resetButtonName');
        },
        changePaymentLevel({ commit }, payload) {
            commit('changePaymentLevel', payload);
        },
        blockPayButton({ commit }) {
            commit('saleButtonActiveChange', false);
        },
        saleButtonActiveChange({ commit }, payload) {
            commit('saleButtonActiveChange', payload);
        },
        saleTermsAgree({ commit }, payload) {
            commit('saleTermsAgree', payload);
        },
        saleChangeAffiliateLink({ commit }, payload) {
            commit('saleChangeAffiliateLink', payload);
        },
        saleChangeSource({ commit }, payload) {
            commit('saleChangeSource', payload);
        },
        saleChangeCashBack({ commit }, payload) {
            commit('saleChangeCashBack', payload);
        },
        saleChangeCupom({ commit }, payload) {
            commit('saleChangeCupom', payload);
            store.dispatch('setDiscountPercentage', 5);
        },
        saleChangeAddressAttribute({ commit }, payload) {
            commit('saleChangeAddressAttribute', payload);
        },
        saleChangeSaveAddress({ commit }, payload) {
            commit('saleChangeSaveAddress', payload);
        },
        saleChangeAddress({ commit }, payload) {
            commit('saleChangeAddress', payload);
        },
        saleClearAddress({ commit }) {
            commit('saleClearAddress');
        },
        saleChangeAddressPayment({ commit }, payload) {
            commit('saleChangeAddressPayment', payload);
        },
        saleClearAddressPayment({ commit }) {
            commit('saleClearAddressPayment');
        },
        setCertificateSelectedNotaries({ commit }, payload) {
            commit('setCertificateSelectedNotaries', payload);
        },
        setCertificateSelectedStates({ commit }, payload) {
            commit('setCertificateSelectedStates', payload);
        },
        setCertificateSelectedCities({ commit }, payload) {
            commit('setCertificateSelectedCities', payload);
        },
        deleteCertificateSelectedNotaries({ commit }, certificateCounter) {
            commit('deleteCertificateSelectedNotaries', certificateCounter);
        },
        deleteCertificateSelectedStates({ commit }, certificateCounter) {
            commit('deleteCertificateSelectedStates', certificateCounter);
        },
        deleteCertificateSelectedCities({ commit }, certificateCounter) {
            commit('deleteCertificateSelectedCities', certificateCounter);
        },
        changSaleId({ commit }, payload) {
            commit('changSaleId', payload);
        },
        changeSavingDeliveryAddress({ commit }, payload) {
            commit('changeSavingDeliveryAddress', payload);
        },
        changeDeliveryAdditionalHasChanged({ commit }, payload) {
            commit('changeDeliveryAdditionalHasChanged', payload);
        },
        setParamCertificate({ commit }, payload) {
            commit('setParamCertificate', payload);
        },
        setParamGroup({ commit }, payload) {
            commit('setParamGroup', payload);
        },
    },
    getters: {
        saleId: state => state.saleId,
        saleLevel: state => state.saleLevel,
        paymentLevel: state => state.paymentLevel,
        saleFormData: state => state.saleFormData,
        salePaying: state => state.salePaying,
        saleButtonActive: state => state.saleButtonActive,
        saleInternational: state => state.saleInternational,
        termsAgree: state => state.termsAgree,
        affiliateLink: state => state.affiliateLink,
        cashBack: state => state.cashBack,
        source: state => state.source,
        address: state => state.address,
        addressPayment: state => state.addressPayment,
        cupom: state => state.cupom,
        saveAddress: state => state.address.saveAddress,
        certificateSelectedNotaries: state => state.certificateSelectedNotaries,
        certificateSelectedStates: state => state.certificateSelectedStates,
        certificateSelectedCities: state => state.certificateSelectedCities,
        savingDeliveryAddress: state => state.savingDeliveryAddress,
        deliveryAdditional: state => state.deliveryAdditional,
        deliveryAdditionalHasChanged: state => state.deliveryAdditionalHasChanged,
        paramCertificate: state => state.paramCertificate,
        paramGroup: state => state.paramGroup,
    }
}
export default sale