const paymentLink = {
    state: () => ({
        "paymentLinkData": {
            "saleId": '',
            "name": '',
            "phone": '',
            "email": '',
            "amount": '',
            "approved": '',
            "certificates": [],
            "createdAt": ''
        },
        'isPaymentLink': false,
        "hash": ''
    }),
    mutations: {
        changePaymentLinkData(state, payload) {
            state.paymentLinkData = payload;
        },
        changeHash(state, payload) {
            state.hash = payload;
        },
        changeIsPaymentLink(state, payload) {
            state.isPaymentLink = payload;
        },
    },
    actions: {
        changePaymentLinkData({ commit }, payload) {
            commit('changePaymentLinkData', payload);
            commit('changeIsPaymentLink', true);

        },
        changeHash({ commit }, payload) {
            commit('changeHash', payload);
        },
    },
    getters: {
        paymentLinkData: state => state.paymentLinkData,
        hash: state => state.hash,
        isPaymentLink: state => state.isPaymentLink,
    }
}
export default paymentLink