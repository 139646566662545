import Axios from 'axios';

export class ResourceAdapter {
    constructor(link) {
        this._link = link;
    }

    post(getFormData = {}) {
        let dataForm = this.getFormData(getFormData);
        return Axios
            .post(this._link, dataForm)
            .then((response) => {
                return response.data
            });
    }

    get(id) {
        return Axios
            .get(this._link, {
                params: {
                    id
                }
            })
            .then(res => res.data);
    }

    getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    put(payload) {
        return Axios
            .put(this._link, payload)
            .then(res => res.data);
    }

    delete(payload) {
        return Axios
            .delete(this._link, {
                params: {
                    payload
                }
            })
            .then(res => res.data);
    }
}