import {doScrolling} from "../../utils/redireciona";
import {CartItemStandard} from "../../models/cart/cartItem/CartItemStandard";

export const excludeCertificate = ({commit}, payload) => {
    let numberToExclude = parseInt(payload);
    commit('certificateNumberListRemove', numberToExclude);
    commit('cartRemove', {counter: numberToExclude});
    commit('certificateDataExclude', numberToExclude);
    commit('certificateNumberActiveChange', 1);
}

export const pay = ({commit}, dataForm) => {
    commit('saleChangeFormData', dataForm);
    commit('saleButtonActiveChange', false);
    commit('salePayingChange', true);
}

export const payBack = ({commit}) => {
    commit('saleButtonActiveChange', true);
    commit('salePayingChange', false);
}

export const addCertificate = ({commit, dispatch}) => {
    commit('certificateNumberListInsert')
    dispatch('saleChangeLevel', 1);
    doScrolling('#main-container');
    dispatch('addCertificateEmpty', {name: 'Selecione uma certidão'});
}

export const addCertificateEmpty = ({getters, commit, dispatch}, {name}) => {
    const cartItem = new CartItemStandard();
    cartItem.basicInfo({
        slug: 'certidao',
        name,
        priceReference: 0
    });
    commit('cartPush', {
        counter: getters.certificateNumberCounter,
        title: 'certificate',
        item: cartItem
    });
}

export const addCertificateService = ({commit, getters}, {counter, certificateSlug, cartType}) => {
    const serviceItem = getters.getServiceBySlug({certificateSlug, cartType});
    if (serviceItem) {
        commit('cartPush', {
            counter,
            title: 'certificate',
            item: serviceItem,
        });
    }
}

export const addAdditionalService = ({commit, getters}, {counter, certificateSlug, cartType}) => {
    const serviceItem = getters.getServiceBySlug({certificateSlug, cartType});
    if(serviceItem) {
        commit('cartPush', {
            counter,
            title: certificateSlug.get(),
            item: serviceItem,
        });
    }
}